import http from '../utils/Http'

export function getBannerList(data) {
    return http({
        url: 'routine-banner/list',
        method: 'GET',
		params: data
    })
}

export function addBanner(data) {
    return http({
        url: 'routine-banner/add',
        method: 'POST',
		data: data
    })
}

export function updateBanner(data) {
    return http({
        url: 'routine-banner/add',
        method: 'PUT',
		data: data
    })
}

export function deleteBanner(id) {
    return http({
        url: 'routine-banner/delete',
        method: 'DELETE',
		data: {
			id: id
		}
    })
}